.TransactionHistorySidebar {
  left: 0px;
  width: 400px;
  height: 100vh;
  background-color: white;
}

.Nifty {
  width: 170px;
  height: 200px;
  margin-bottom: 30px;
  margin-left: 10px;
  cursor: pointer;
  /* Rectangle 5: */
  background-image: linear-gradient(-180deg, #FFFFFF 0%, #F7F6F6 100%);
  border: 1px solid #979797;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  border-radius: 7px;
  /* Screen Shot 2018-08-21 at 9.56.34 AM: */
  /* CryptoKitty 123: */
}

.WalletTitle {
  font-family: 'OpenSans',sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #030303;
  text-align: left;
  padding-top: 10px;
  padding-left: 20px;
}

.NiftyImage {
  width: 145px;
  height: 145px;
  margin-right: 15px;
  margin-bottom: 10px;
}

.NiftiesOnDisplay {
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  align-self: center;
  margin: 0 auto;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.TransactionToText {
  font-family: 'OpenSans',sans-serif;
  font-weight: 600;
  color: black;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.TransactionToBuyText {
  font-family: 'OpenSans',sans-serif;
  font-weight: 600;
  color: black;
  font-size: 14px;
  margin-left: 0px;
  margin-right: 5px;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.TransactionTitle {
  font-family: 'OpenSans',sans-serif;
  font-weight: 600;
  color: black;
  font-size: 14px;
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ActivePagination {
  font-family: 'OpenSans',sans-serif;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
}

.NotActivePagination {
  font-family: 'OpenSans',sans-serif;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  margin-left: 10px;
}

.NiftGatewayCollectionHeaderLogo {
  width: 250px;
  height: 42px;
  margin-top: 16px;
  margin-right: 80px;
}

.MetaMaskCollectionHeaderLogo {
  width: 120px;
  height: 42px;
  margin-top: 16px;
  margin-right: 220px;
}

.NiftyGatewayCollectionHeaderText {
  font-family: 'OpenSans',sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-left: 5px;
  margin-top: 10px;
}

.NiftyGatewayCollectionHeaderBox {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  margin-left: 15px;
  margin-bottom: 10px;
  width: 350px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.NFTGLogoBox {
  width: 100%;
}

.selectedPage {
  font-family: 'OpenSans',sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.paginationNumsList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: left;
}

.NoMetaMaskText {
  font-family: 'OpenSans',sans-serif;
  font-weight: 400;
  color: grey;
  font-size:14px;
  text-align: left;
  margin-left: 23px;
  margin-top: 10px;
}

.NiftiesInNFTGCollection {
  font-family: 'OpenSans',sans-serif;
  font-weight: 400;
  color: grey;
  font-size: 20px;
  margin-left: 23px;
  margin-top: 10px;
  text-align: left;
  width: 100%;
}

.TransactionInfo {
  font-family: 'OpenSans',sans-serif;
  font-weight: 600;
  color: grey;
  font-size: 12px;
  margin-left: 5px;
  text-align: left;
}

.PendingText{
  font-family: 'OpenSans',sans-serif;
  font-weight: 600;
  color: grey;
  font-size: 12px;
  text-align: left;
}

.ConfirmedText {
  font-family: 'OpenSans',sans-serif;
  font-weight: 600;
  color: #417505;
  font-size: 12px;
  text-align: left;
}

.transactionObject {

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.TransferInfoBox {
  border-radius: 7px;
  margin-right: 10px;
  margin-left: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  width: 90%;
  height: 100px;
  cursor: pointer;
  /* Rectangle 5: */
  background-image: linear-gradient(-180deg, #FFFFFF 0%, #F7F6F6 100%);
  border: .5px solid #979797;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.TransferListImage {
  width: 60px;
  height: 60px;
}

.TransferInfoHeaders {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;

}

.TransactionsNote {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  color: #000000;
  letter-spacing: -0.68px;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.TestingText1 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400i;
  font-style: italic;
  font-size: 20px;
}

.TestingText2 {
  font-family: 'Open Sans', sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 20px;
}

.PurchaseTransferToolbar {
  background-color: transparent;
  font-family: 'OpenSans';
  font-size: 18px;
  margin: 0 auto;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin-left: 20px;
  margin-right: 5px;
  margin-bottom: 0px;
}

.TransferInfoHeaders {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;

}

.hoverButtonTransactionHistoryObject:hover{
  cursor:pointer;
  background-color: #f5f5f5;
}

.BodyBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

.BodyLight {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.BodyNormal {
  font-family: 'Open Sans', sans-serif;
}
