.App {

  html: {margin:0;padding:0;height:100%;width:100%};
  backgroundColor: #414141;
}


html, body, #app, #app>div {
  height: 100%
}

.full-height {
  height: 100%;
  background: yellow;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background-enter {
  transform: translate(100%);
}
.background-enter.background-enter-active {
  transform: translate(0%);
  transition: transform 1000ms ease-in-out;
}
.background-leave {
  transform: translate(0%);
}
.background-leave.background-leave-active {
  transform: translate(-100%);
  transition: transform 1000ms ease-in-out;
}

.hoverLight:hover{
  cursor:pointer;
  opacity: .3;
  font-family: 'IBM Plex Sans', sans-serif;
}

.bodyMedium {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600,
}

.bodyNormal {
  font-family: 'IBM Plex Sans', sans-serif;
}

.bodyBold {
font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 700;
}

.bodyNormal {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
}

.bodyNormalHoverLight {
  font-family: 'IBM Plex Sans', sans-serif;

}

.bodyLight {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 300;
}

.bodyLightHoverLight {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 300;
}

.bodyLightHoverLight:hover{
  cursor:pointer;
  opacity: .3;
  font-family: 'IBM Plex Sans', sans-serif;
}

.hoverRow:hover {

  opacity: .3;
  cursor: pointer;
}


.hoverLightBoxShadow:hover{
  cursor:pointer;
  opacity: .7;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
}
